import React from 'react';
import './App.css';
import NavbarComponent from './components/NavbarComponent/NavbarComponent';
import FooterComponent from './components/FooterComponent/FooterComponent';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import HomePage from './components/HomePage/HomePage';
import Games from './components/Games/Games';
import About from './components/About/About';
import Terms from './components/Terms/Terms';
import News from './components/News/News';
import Blog from './components/Blog/Blog';
import Privacy from './components/Privacy/Privacy';
import FAQ from './components/FAQ/FAQ';

function App() {
  return (
    <Router>
      <div className="App">
        <NavbarComponent/>
        <Switch>
          <Route exact path={ROUTES.HOME_PAGE} component={HomePage} />
          <Route exact path={ROUTES.GAMES} component={Games} />
          <Route exact path={ROUTES.NEWS} component={News} />
          <Route exact path={ROUTES.BLOG} component={Blog} />
          <Route exact path={ROUTES.ABOUT} component={About} />
          <Route exact path={ROUTES.TERMS} component={Terms} />
          <Route exact path={ROUTES.PRIVACY} component={Privacy} />
          <Route exact path={ROUTES.FAQ} component={FAQ} />
        </Switch>
        <FooterComponent/>
      </div>
    </Router>
  );
}

export default App;
