import React from 'react';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import FormControl from 'react-bootstrap/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Image from 'react-bootstrap/Image';
import logo from '../../images/site/tsalogo.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { LinkContainer } from "react-router-bootstrap";

const NavbarComponent = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
      <LinkContainer to="/">
        <Navbar.Brand>
          <Image src={logo} height="100px" width="100px" roundedCircle />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Container fluid="true">
        <Row>
          <Nav className="mr-auto">
            <LinkContainer to="/games">
              <Nav.Link>Games</Nav.Link>
            </LinkContainer>
            {/* <LinkContainer to="/about">
              <Nav.Link>About</Nav.Link>
            </LinkContainer> */}
            {/* <NavDropdown title="More" id="collasible-nav-dropdown">
              <LinkContainer to="/news">
                <NavDropdown.Item>News</NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <LinkContainer to="/blog">
                <NavDropdown.Item>Blog</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown> */}
          </Nav>
          </Row>
          {/* <Row>
            <Col sm={6}>
              <InputGroup>
                <FormControl type="text" placeholder="Search for games" />
                <InputGroup.Append>
                  <Button variant="outline-success"><FontAwesomeIcon icon={faSearch} /></Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col sm={6}>
              <ButtonGroup>
                <Button variant="outline-success"><FontAwesomeIcon icon={faSignInAlt} /> Sign In</Button>
                <Button variant="outline-success"><FontAwesomeIcon icon={faUserPlus} /> Sign Up</Button>
              </ButtonGroup>
            </Col>
          </Row> */}
        </Container>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavbarComponent;
