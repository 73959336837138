import React from 'react'

const News = () => {
  return (
    <div>
      <h1>
        News
      </h1>
      Something:
      <li>Info</li>
      <li>Credits?</li>
      <li>Company Link</li>
    </div>
  )
}

export default News;
