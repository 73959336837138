import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const HomePage = () => {
  return (
    <Container>
      <Row>
        <Col xs={2}></Col>
        <Col xs={8}>
          <div>
            <h1>
              Welcome to the Top Secret Arcade
            </h1>
            <h2>
              Coming soon!
            </h2>

          </div>
        </Col>
        <Col xs={2}></Col>
      </Row>
    </Container>
  )
}

export default HomePage;
