import React from 'react'

const FAQ = () => {
  return (
    <div>
      <h1>
        FAQ
      </h1>
      Something:
      <li>Info</li>
      <li>Credits?</li>
      <li>Company Link</li>
    </div>
  )
}

export default FAQ;
