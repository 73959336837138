import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from "react-router-bootstrap";

const FooterComponent = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="bottom">
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="justify-content-center" activeKey="/home">
          <Nav.Item>
            <LinkContainer to="/">
              <Nav.Link eventKey="company_website">
                <FontAwesomeIcon icon={faCopyright} /> 2020
              </Nav.Link>
            </LinkContainer>
          </Nav.Item>
          {/* <Nav.Item>
          <LinkContainer to="/terms">
              <Nav.Link href="">
              <FontAwesomeIcon color="white" icon={faAngleRight} /> Terms
              </Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to="/privacy">
              <Nav.Link eventKey="privacy">
                <FontAwesomeIcon color="white" icon={faAngleRight} /> Privacy
              </Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to="/about">
              <Nav.Link href="">
              <FontAwesomeIcon color="white" icon={faAngleRight} /> About
              </Nav.Link>
            </LinkContainer>
          </Nav.Item> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default FooterComponent;
