import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Media from 'react-bootstrap/Media'

const Games = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col xs={2}></Col>
          <Col xs={8}>
            <div>
              <h1>
                Games
              </h1>
              <Media>
                {/* <img
                  width={64}
                  height={64}
                  className="mr-3"
                  src="holder.js/64x64"
                  alt="Generic placeholder"
                /> */}
                <Media.Body>
                  <h5>TriviUP</h5>
                  <p>
                    A trivia game. Coming soon!
                  </p>
                </Media.Body>
              </Media>
            </div>
          </Col>
          <Col xs={2}></Col>
        </Row>
      </Container>
    </div>
  )
}

export default Games;
