import React from 'react'

const Terms = () => {
  return (
    <div>
      <h1>
        Terms
      </h1>
      Something:
      <li>Info</li>
      <li>Credits?</li>
      <li>Company Link</li>
    </div>
  )
}

export default Terms;
